<template>
    <div class="pt-14 bg-gradient-to-br from-blue-100 via-white to-blue-100 min-h-screen">
        <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div class="flex flex-col items-start max-w-4xl mx-auto">
                <h1 class="font-bold text-5xl pb-5 text-blue-900">Magnetic Materials Database</h1>
                <p class="text-left text-lg text-gray-700 mb-8">
                    Powered by advanced large language models, our project introduces a magnetic materials database aimed at accelerating the discovery of new materials. This AI-driven platform offers open web-based access to a vast collection of information on magnetic materials, all extracted and synthesized from scientific literature. It serves as a pivotal resource for researchers and innovators seeking to explore and design novel magnetic materials by providing direct access to curated data derived from the forefront of AI technology.
                </p>
                <div class="flex flex-wrap gap-4 mb-8">
                    <button @click="onClickSignIn" class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                        Register or Login
                    </button>
                    <button @click="navigateToRandomSample" class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                        See a Random Material
                    </button>
                    <span class="bg-white text-blue-800 font-semibold py-2 px-4 inline-flex items-center border-2 border-blue-600 rounded">
                        Materials Number: {{ formatNumber(materials_number) }}
                    </span>
                </div>
            </div>
        </div>
        
        <div class="bg-white py-12">
            <div class="container mx-auto px-4 sm:px-6 lg:px-8">
                <h2 class="text-3xl font-bold text-center text-blue-900 mb-8">Explore Our Database</h2>
                <PeriodicTable></PeriodicTable>
            </div>
        </div>
    </div>
</template>

<script>
import PeriodicTable from '../components/PeriodicTable.vue'
import { useLogto } from "@logto/vue";
import { redirectUrl } from '../consts/logto';
export default {
    name: 'HomePage',
    components: {
        // SignIn,
        // SingOut
        PeriodicTable
    },
    data() {
      return {
        materials_number: 0, // 用于存储获取的 JSON 数据
      };
    },
    mounted() {
        this.fetchMaterialsNum(); // 组件加载时调用方法获取材料数量
    },
    setup() {
        const { signIn } = useLogto();
        const onClickSignIn = () => signIn(redirectUrl);
        return { onClickSignIn };
    },
    methods: {
    navigateToRandomSample() {
      // 使用编程式导航跳转到 /randomsample 路径
      this.$router.push('/randomsample');
    },
    async fetchMaterialsNum() {
        try {
          const url = '/backend/number'; // 您的 JSON 数据源地址
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          this.materials_number = Number(await response.text());
        } catch (error) {
          console.error('There has been a problem with your fetch operation:', error);
        }
      },
    formatNumber(number) {
        return number.toLocaleString();
    },
  },
}
</script>
