import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import CallbackView from "@/views/CallBackView.vue";

// 定义路由配置
const routes = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage,
    meta: {
      title: 'Home - Magnetic Materials',
      description: 'Welcome to the Magnetic Materials homepage. Discover advanced large language models and a comprehensive magnetic materials database.'
    }
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackView,
    meta: {
      title: 'Callback - Magnetic Materials',
      description: 'Callback page for Magnetic Materials.'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue'), // 懒加载
    meta: {
      title: 'About Us - Magnetic Materials',
      description: 'Learn more about Magnetic Materials, our mission, and our team.'
    }
  },
  {
    path: '/database',
    name: 'DatabasePage',
    component: () => import('@/views/DatabasePage.vue'), // 懒加载
    meta: {
      title: 'Database - Magnetic Materials',
      description: 'Explore our extensive magnetic materials database.'
    }
  },
  {
    path: '/randomsample',
    name: 'RandomSamplePage',
    component: () => import('@/views/RandomSamplePage.vue'), // 懒加载
    meta: {
      title: 'Random Sample - Magnetic Materials',
      description: 'Get a random sample of magnetic materials from our database.'
    }
  },
  { 
    path: '/detail/:doi', 
    name: 'Detail', 
    component: () => import('@/views/DetailPage.vue'),
    props: true, // 启用 props 将路由参数传递给组件
    meta: {
      title: 'Detail - Magnetic Materials',
      description: 'Detailed information about a specific magnetic material.'
    }
  },
  {
    path: '/search',
    name: 'SearchPage',
    component: () => import('@/views/SearchPage.vue'), // 懒加载
    meta: {
      title: 'Search - Magnetic Materials',
      description: 'Search for magnetic materials in our database.'
    }
  },

];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 设置全局路由守卫来更新页面标题和描述
router.beforeEach((to, from, next) => {
  // 确保标题是字符串类型
  const title = typeof to.meta.title === 'string' ? to.meta.title : 'Magnetic Materials';
  document.title = title;

  // 确保描述是字符串类型
  const description = typeof to.meta.description === 'string' ? to.meta.description : 'Magnetic Materials database aimed at accelerating the discovery of magnetic materials.';
  
  // 更新 meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  } else {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'description');
    metaTag.setAttribute('content', description);
    document.head.appendChild(metaTag);
  }

  next();
});

export default router;